import script from "./CTileBtn.vue?vue&type=script&setup=true&lang=ts"
export * from "./CTileBtn.vue?vue&type=script&setup=true&lang=ts"

import "./CTileBtn.vue?vue&type=style&index=0&id=7ff7e19e&lang=scss"

const __exports__ = script;

export default __exports__
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import qInstall from "../../../../node_modules/.pnpm/vue-cli-plugin-quasar@4.0.4/node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QBtn,QIcon});
