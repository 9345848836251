// Configurations that can be used on quasar components. Example:
// <q-input v-bind="input" />

export const input = {
  class: 'c-input c-input--default',
  outlined: true,
  hint: '',
  dropdownIcon: 'mdi-chevron-down',
}

export const inputDense = {
  class: 'c-input c-input--dense',
  outlined: true,
  dense: true,
  dropdownIcon: 'mdi-chevron-down',
}

export const checkbox = {
  class: 'c-checkbox',
  dense: true,
}

export const radio = {
  class: 'c-radio',
  dense: true,
  checkedIcon: 'mdi-checkbox-marked-circle',
  uncheckedIcon: 'mdi-checkbox-blank-circle-outline',
}

export const button = {
  class: 'c-btn',
  rounded: true,
  unelevated: true,
  noCaps: true,
  color: 'primary',
  textColor: 'grey-darkest',
}

export const table = {
  class: 'c-table',
  flat: true,
  bordered: false,
  separator: 'horizontal' as const,
  rowsPerPageOptions: [0],
  wrapCells: true,
  hideSelectedBanner: true,
  paginationLabel: () => '',
  rowsPerPageLabel: ' ',
  hideNoData: true,
  hideBottom: true,
}

export const tree = {
  class: 'c-tree',
  noConnectors: true,
  icon: 'mdi-chevron-right',
}

export const quasarComponentConfigurations = {
  input,
  inputDense,
  checkbox,
  radio,
  button,
  table,
  tree,
}
