<template>
  <q-header
    class="bg-surface"
    data-id="site-header"
  >
    <q-toolbar class="p-0 h-15">
      <slot />
    </q-toolbar>
  </q-header>
</template>
