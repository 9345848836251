import script from "./CDrawer.vue?vue&type=script&setup=true&lang=ts"
export * from "./CDrawer.vue?vue&type=script&setup=true&lang=ts"

import "./CDrawer.vue?vue&type=style&index=0&id=de02375c&lang=css"

const __exports__ = script;

export default __exports__
import QDrawer from 'quasar/src/components/drawer/QDrawer.js';
import qInstall from "../../../../node_modules/.pnpm/vue-cli-plugin-quasar@4.0.4/node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QDrawer});
