export default {
  inri: {
    validation: {
      required: 'This field is required',
      maxLength: 'This field can be no longer than {parsedLength} characters long',
      minLength: 'This field must be at least {parsedLength} characters long',
      email: 'This field must be a valid email address',
      url: 'This field must be a valid URL',
      https: 'This field must be a valid URL using https',
      extension: 'Valid file extensions: {extensions}',
      contains: 'This field is not valid. Must contain {match}',
      notContains: 'This field is not valid. Must not contain {match}',
      notBeginsWith: 'This field is not valid. Must not begin with {match}',
      notEndsWith: 'This field is not valid. Must not end with {match}',
      maxFileSize: 'Files can be no larger than {parsedFileSize}',
      maxImageSize: 'Images can be no larger than {size}',
      fileSizeTooBig: 'file size too big',
      fileTypeInvalid: 'file type invalid',
    },
    error: {
      unauthorized: 'Unauthorized',
      session_expired: 'Your session has expired and you will be returned to login.',
      internal_server_error: 'Internal server error',
      unknown_error: 'Unknown error',
      undefined_error: 'Undefined error',
      file_error: 'Could not load file',
      error: 'Error',
      network_error: 'Network error',
      error_occurred: 'An error occurred',
      service_unavailable: 'Service unavailable',
      service_unavailable_try_again: 'Request could not be handled. Please try again in a minute',
      not_found: 'Not found',
      requested_asset_not_found: 'The requested asset does not exist',
      folder_does_not_exist: 'Folder does not exist',
      could_not_retrieve_assets: 'Folder does not exist. Could not retrieve assets',
      error_code: 'error code: {code}',
      page_does_not_exist: 'Sorry! This page does not exist!',
    },
    common: {
      ok: 'ok',
      save: 'save',
      cancel: 'cancel',
      confirm: 'confirm',
      yes: 'yes',
      delete: 'delete',
      close: 'close',
      download: 'download',
      more: 'more',
    },
    dialog: {
      unsaved_changes_title: 'unsaved changes',
      unsaved_changes_message: 'changes are not saved, do you want to continue?',
      confirm_tooltip_invalid_fields: 'all required fields needs to have a valid value',
      confirm_delete_title: 'are you sure you want to delete?',
    },
    toggle_actions: {
      sidebar: 'toggle sidebar',
      grid_view: 'grid view',
      table_view: 'table view',
    },
    select: {
      select_all: 'select all',
      no_results: 'no results',
    },
    navigate_back: 'go back',
    languages: {
      en: 'english',
      de: 'german',
      fr: 'french',
      es: 'spanish',
    },
    dropzone: {
      hint: 'drop files to instantly add them to {0}',
      hint_general: 'drop files to instantly add them here',
      invalid_drop_items: 'Invalid item(s) dragged into the dropzone',
    },
    toggle_drawer: 'toggle drawer',
    authentication: {
      session_expired: 'your session has expired',
      session_inactive: 'you have been inactive for too long, or you have been logged out of the main inriver portal.',
      log_in: 'log in again',
    },
  },
}
