export default {
  inri: {
    common: {
      ok: 'OK',
      save: 'Speichern',
      cancel: 'Abbrechen',
      confirm: 'Bestätigen',
      yes: 'Ja',
    },
    languages: {
      en: 'englisch',
      de: 'deutsch',
      fr: 'französisch',
      es: 'spanisch',
    },
  },
}
