import { Exception } from './Exception'
import { ErrorFactory } from './ErrorFactories'
import logger from './logger'
import notify from '../notify'
import dialog from '../dialog'

export default function handleError (errInfoObj, vueInfoObj?) {
  const err = errInfoObj.err

  if (err instanceof Exception) {
    const parsedError = ErrorFactory.createHelpfulError({
      defaultMsg: err.msg,
      err: err.error,
    })

    dialog.info({
      title: parsedError.title,
      text: parsedError.msg,
    })

    errInfoObj.handledBy = 'handleError'
    logger.logTrace(errInfoObj, vueInfoObj)
  } else if (err) {
    const parsedError = ErrorFactory.createHelpfulError({ err })
    notify.error(parsedError.msg)

    if (err.type === 'unhandledrejection') {
      errInfoObj.handledBy = '-'
      logger.logTrace(errInfoObj, vueInfoObj)
    } else {
      logger.logException(err)
    }
  }
}
