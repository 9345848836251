import script from "./CExpansionItemWithCheckbox.vue?vue&type=script&setup=true&lang=ts"
export * from "./CExpansionItemWithCheckbox.vue?vue&type=script&setup=true&lang=ts"

import "./CExpansionItemWithCheckbox.vue?vue&type=style&index=0&id=bd4212de&lang=css"

const __exports__ = script;

export default __exports__
import QExpansionItem from 'quasar/src/components/expansion-item/QExpansionItem.js';
import QCheckbox from 'quasar/src/components/checkbox/QCheckbox.js';
import qInstall from "../../../../node_modules/.pnpm/vue-cli-plugin-quasar@4.0.4/node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QExpansionItem,QCheckbox});
