<template>
  <q-page-container>
    <div class="relative">
      <q-page class="overflow-hidden">
        <router-view v-slot="{ Component }">
          <component :is="Component" />
        </router-view>
      </q-page>
    </div>
  </q-page-container>
</template>
