import { RouteLocationNormalized, RouteLocationNormalizedLoaded, RouteRecordNormalized } from 'vue-router'

function isRouteLocation (arg): arg is RouteLocationNormalized | RouteLocationNormalizedLoaded {
  return arg && arg.params
}

export const getRouteTitle = (
  route: RouteLocationNormalized | RouteLocationNormalizedLoaded | RouteRecordNormalized | string,
  to?: RouteLocationNormalized | RouteLocationNormalizedLoaded | RouteRecordNormalized | string,
) => {
  let title = typeof route !== 'string' ? route.meta.title as string : route

  // If "to" is not passed as a parameter then use the current route as "to"
  to = to || route

  if (isRouteLocation(to)) {
    for (const [key, value] of Object.entries(to.params)) {
      title = title.replace(`:${key}`, value as string)
    }
  }

  return title
}
