import { defineStore } from 'pinia'
import { getFolders, addFolder, updateFolder, deleteFolder, deleteAsset, getAssetsInFolder } from '@/apis/mediaManagerApi'
import { findFolder, getParentNodes } from '@/utils/helpers'
import { FolderReadModel, FolderUpdateModel, AssetReadModel } from '@inriver/types'

interface State {
  initialized: boolean,
  currentFolderId: string | null,
  allFolders: FolderReadModel[],
  assetsInFolder: AssetReadModel[],
  selectedFolders: FolderReadModel[],
  selectedAssets: AssetReadModel[],
}

export const useFolderStore = defineStore({
  id: 'folder',
  state: (): State => ({
    initialized: false,
    currentFolderId: null,
    allFolders: [],
    assetsInFolder: [],
    selectedFolders: [],
    selectedAssets: [],
  }),
  getters: {
    currentFolder: (state) => {
      if (state.currentFolderId) {
        return findFolder(state.currentFolderId, state.allFolders)
      }

      return null
    },
    ancestors: (state): FolderReadModel[] => {
      if (state.currentFolderId) {
        return getParentNodes(state.currentFolderId, state.allFolders) || []
      }

      return []
    },
    subfolders (): FolderReadModel[] {
      if (this.currentFolder) {
        return this.currentFolder?.children || []
      }

      return this.allFolders || []
    },
  },
  actions: {
    async init () {
      this.allFolders = await getFolders()
      this.initialized = true
    },
    async refreshFolders () {
      this.allFolders = await getFolders()
    },
    async refreshAssetsInFolder () {
      this.assetsInFolder = await getAssetsInFolder(this.currentFolderId)
    },
    async addFolder (name: string) {
      await addFolder({
        name: name,
        parentId: this.currentFolderId,
      })
    },
    async deleteFolder (folderId: string) {
      await deleteFolder(folderId)
    },
    async deleteAsset (assetId: string) {
      await deleteAsset(assetId)
    },
    async updateFolder (folderId: string, newAttrs: FolderUpdateModel) {
      await updateFolder(folderId, newAttrs)
    },
    clearSelection () {
      this.selectedFolders = []
      this.selectedAssets = []
    },
  },
})
