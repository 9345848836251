import { defineStore } from 'pinia'
import { AssetReadModel } from '@inriver/types'
import { getAssets, deleteAsset } from '@/apis/mediaManagerApi'

interface State {
  assetsInBin: AssetReadModel[],
  selectedAssets: AssetReadModel[],
  isEmpty: boolean,
}

export const useBinStore = defineStore({
  id: 'bin',
  state: (): State => ({
    assetsInBin: [],
    selectedAssets: [],
    isEmpty: false,
  }),
  actions: {
    async populateBin () {
      this.assetsInBin = await getAssets(12)
    },
    async hardDeleteAsset (assetId) {
      // Optimistic deletion before the actual request
      this.assetsInBin = this.assetsInBin.filter(r => r.id !== assetId)

      // Delete for real
      await deleteAsset(assetId)
    },
    clearSelection () {
      this.selectedAssets = []
    },
  },
})
