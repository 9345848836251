import { App } from 'vue'

// Global components
import CTileBtn from '../components/CTileBtn.vue'
import CGrid from '../components/CGrid.vue'
import CBtn from '../components/CBtn.vue'
import CIconBtn from '../components/CIconBtn.vue'
import CTableActions from '../components/CTableActions.vue'
import CContextMenu from '../components/CContextMenu.vue'
import CMenuItem from '../components/CMenuItem.vue'
import CDirtyCheck from '../components/CDirtyCheck.vue'
import CAutoBreadcrumbs from '../components/CAutoBreadcrumbs.vue'
import CDialog from '../components/CDialog.vue'
import CDatePicker from '../components/CDatePicker.vue'
import CTimePicker from '../components/CTimePicker.vue'
import CColorPicker from '../components/CColorPicker.vue'
import CSearch from '../components/CSearch.vue'
import CTooltip from '../components/CTooltip.vue'
import CSlideEffect from '../components/CSlideEffect.vue'
import CExpansionItem from '../components/CExpansionItem.vue'
import CExpansionItemWithCheckbox from '../components/CExpansionItemWithCheckbox.vue'
import CSelect from '../components/CSelect.vue'
import CSpinner from '../components/CSpinner.vue'
import CSection from '../components/CSection.vue'
import CSectionTitle from '../components/CSectionTitle.vue'
import CSmallCard from '../components/cards/CSmallCard.vue'
import CBannerCard from '../components/cards/CBannerCard.vue'
import CPageContainer from '../components/CPageContainer.vue'
import CPageSpinner from '../components/CPageSpinner.vue'
import CDrawer from '../components/CDrawer.vue'
import CHeader from '../components/CHeader.vue'
import CTreeNode from '../components/CTreeNode.vue'
import CToggleSidebarBtn from '../components/CToggleSidebarBtn.vue'
import CDialogCloseBtn from '../components/CDialogCloseBtn.vue'
import CToggleGridView from '../components/CToggleGridView.vue'
import CPageError from '../components/CPageError.vue'
import CDeleteTileBtn from '../components/CDeleteTileBtn.vue'
import CFileDropzone from '../components/CFileDropzone.vue'

// Header components
import CHeaderLinks from '../components/CHeaderLinks.vue'
import CHeaderMenuLink from '../components/CHeaderMenuLink.vue'
import CHeaderSearch from '../components/CHeaderSearch.vue'
import CDrawerButton from '../components/CDrawerButton.vue'

// Layout fragments
import CPageBlank from '../layouts/layout-fragments/CPageBlank.vue'
import CPageTitle from '../layouts/layout-fragments/CPageTitle.vue'
import CPageOuterWrapper from '../layouts/layout-fragments/CPageOuterWrapper.vue'
import CPageLeftSidebar from '../layouts/layout-fragments/CPageLeftSidebar.vue'
import CPageRightSidebar from '../layouts/layout-fragments/CPageRightSidebar.vue'
import CPageFlexCol from '../layouts/layout-fragments/CPageFlexCol.vue'
import CPageAutoBreadcrumbs from '../layouts/layout-fragments/CPageAutoBreadcrumbs.vue'
import CPageMainContent from '../layouts/layout-fragments/CPageMainContent.vue'
import CPageBackButton from '../layouts/layout-fragments/CPageBackButton.vue'

// Preconfigured layouts
import CLayoutWithSidebars from '../layouts/CLayoutWithSidebars.vue'
import CLayoutWithBackButton from '../layouts/CLayoutWithBackButton.vue'
import CLayoutBlank from '../layouts/CLayoutBlank.vue'

// register components
export const registerComponents = (Vue: App): void => {
  Vue.component('CTileBtn', CTileBtn)
  Vue.component('CBtn', CBtn)
  Vue.component('CGrid', CGrid)
  Vue.component('CIconBtn', CIconBtn)
  Vue.component('CTableActions', CTableActions)
  Vue.component('CContextMenu', CContextMenu)
  Vue.component('CMenuItem', CMenuItem)
  Vue.component('CDirtyCheck', CDirtyCheck)
  Vue.component('CAutoBreadcrumbs', CAutoBreadcrumbs)
  Vue.component('CDialog', CDialog)
  Vue.component('CDatePicker', CDatePicker)
  Vue.component('CTimePicker', CTimePicker)
  Vue.component('CColorPicker', CColorPicker)
  Vue.component('CSearch', CSearch)
  Vue.component('CTooltip', CTooltip)
  Vue.component('CSlideEffect', CSlideEffect)
  Vue.component('CExpansionItem', CExpansionItem)
  Vue.component('CExpansionItemWithCheckbox', CExpansionItemWithCheckbox)
  Vue.component('CSelect', CSelect)
  Vue.component('CSpinner', CSpinner)
  Vue.component('CSection', CSection)
  Vue.component('CSectionTitle', CSectionTitle)
  Vue.component('CSmallCard', CSmallCard)
  Vue.component('CBannerCard', CBannerCard)
  Vue.component('CPageContainer', CPageContainer)
  Vue.component('CPageSpinner', CPageSpinner)
  Vue.component('CDrawer', CDrawer)
  Vue.component('CHeader', CHeader)
  Vue.component('CTreeNode', CTreeNode)
  Vue.component('CToggleSidebarBtn', CToggleSidebarBtn)
  Vue.component('CDialogCloseBtn', CDialogCloseBtn)
  Vue.component('CToggleGridView', CToggleGridView)
  Vue.component('CPageError', CPageError)
  Vue.component('CDeleteTileBtn', CDeleteTileBtn)
  Vue.component('CFileDropzone', CFileDropzone)

  // Header components
  Vue.component('CHeaderLinks', CHeaderLinks)
  Vue.component('CHeaderMenuLink', CHeaderMenuLink)
  Vue.component('CHeaderSearch', CHeaderSearch)
  Vue.component('CDrawerButton', CDrawerButton)

  // Page and layout components
  Vue.component('CPageBlank', CPageBlank)
  Vue.component('CPageTitle', CPageTitle)
  Vue.component('CPageOuterWrapper', CPageOuterWrapper)
  Vue.component('CPageLeftSidebar', CPageLeftSidebar)
  Vue.component('CPageRightSidebar', CPageRightSidebar)
  Vue.component('CPageFlexCol', CPageFlexCol)
  Vue.component('CPageAutoBreadcrumbs', CPageAutoBreadcrumbs)
  Vue.component('CPageMainContent', CPageMainContent)
  Vue.component('CPageBackButton', CPageBackButton)
  Vue.component('CLayoutWithSidebars', CLayoutWithSidebars)
  Vue.component('CLayoutWithBackButton', CLayoutWithBackButton)
  Vue.component('CLayoutBlank', CLayoutBlank)
}
