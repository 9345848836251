export default {
  media_manager: {
    media: 'media',
  },
  folders: {
    add_new_folder: 'add new folder',
    folder_name_label: 'folder name',
    add_folder_placeholder: 'new folder',
    rename_folder_placeholder: 'new name',
    folder_added_success: 'folder added successfully',
    folder_renamed_success: 'folder renamed successfully',
    forbidden_prefix_suffix_error: 'a folder name cannot start or end with the characters: . - _',
    folder_name_is_not_unique: 'the folder name needs to be unique',
    could_not_fetch_contents: 'could not fetch folder contents',
    open_in_folder: 'Open in folder',
  },
  share: {
    share_collection: 'share collection',
    collection_shared_success: 'collection successfully shared',
  },
  delete_content: {
    delete: 'delete',
    confirm_deletion_text: 'the selected content will be deleted',
    confirm_asset_deletion_text: 'this asset and all it\'s content will be deleted',
    success: 'selected content deleted successfully',
  },
  asset_selection: {
    selected_of: '{selectedCount} of {totalCount} selected',
    file: 'file | files',
    folder: 'folder | folders',
  },
  upload: {
    upload_from_desktop: 'upload from desktop',
    invalid_request_data: 'Invalid request data received',
    uploading: 'uploading {0}',
    files: '1 file | {n} files',
    upload_complete: 'upload complete {0}',
    upload_successful: 'upload successful',
    upload_failed: 'upload failed',
    n_failed: '{n} failed',
    could_not_parse_file: 'could not parse file',
    could_not_upload_file: 'could not upload file',
    file_exists: 'A file with the name {filename} already exists. Please rename the file or go to the existing resource and replace the media',
    cancelled: 'cancelled',
  },
  asset_sizes: {
    original: 'original',
    small: 'small',
    medium: 'medium',
    large: 'large',
    square: 'square',
  },
  asset_data_options: {
    csv_metadata: 'CSV metadata',
    xml_metadata: 'XML metadata',
    download_packages: 'Download packages',
  },
  versioning: {
    version_history: 'version history',
    version_revert: 'revert',
    confirm_revert_title: 'revert to this version?',
    confirm_revert_text: '{currentWorkingCopy} will revert to {fileVersion} from {fileDate}, {fileTime}',
    success: '{currentWorkingCopy} reverted successfully',
  },
  common: {
    preview: 'preview',
    error_loading_asset: 'error loading asset',
    filter: 'filter',
    search: 'search',
    order_by: 'order by',
    sort_by: 'sort by',
    select_all: 'select all',
    metadata: 'metadata',
    file_url: 'file url',
    share: 'share',
    access_to: 'access to',
    add_users_or_groups_or_emails: 'add users, groups or email addresses...',
    add_optional_message: 'add message (optional)',
    write_optional_message: 'write optional message',
    recipients_can: 'recipients can',
    size_formats: 'size formats',
    rename: 'rename',
  },
  asset_table: {
    type: 'type',
    image: 'image',
    filename: 'name',
    tags: 'tags',
    created: 'created',
    updated: 'updated',
  },
  sort_by: {
    alphabetical_ascending: 'name a - z',
    alphabetical_descending: 'name z - a',
    created_date_ascending: 'old - new',
    created_date_descending: 'new - old',
  },
  related_assets: 'related assets',
  show_related_assets: 'show related assets',
  tags: {
    success: 'tags changed successfully',
    modified_text: 'max length is 20 characters. your text has been modified to fit the requirements',
    show_more: 'show more',
    add_tag: 'add new tag',
    edit_tags: 'edit tags',
  },
  edit: 'edit',
  save: 'save',
  recycle_bin: {
    bin: 'bin',
    open: 'open',
    empty_bin: 'empty bin',
    empty_bin_confirmation: 'bin emptied',
    delete_all: 'this action will permanently delete all files in the bin. this can not be undone',
    ongoing_delete: 'deleting {amount} of {total}',
    permanently_deleted: '1 file deleted permanently | {amount} files deleted permanently',
    processing: 'processing',
    trash_empty: 'trash is empty ...',
    deleted_files: 'deleted files',
    delete_dialog: {
      text: 'this action can not be undone',
    },
    restore: 'restore',
    restore_confirm_text: 'do you want to restore the selected objects?',
    objects_restored: 'object has been restored | {amount} objects has been restored',
  },
  information_box: {
    information: 'information',
  },
  change_environment: 'change environment',
  sign_out: 'sign out',
  user: {
    heading: 'user options',
    username: 'username',
    first_name: 'first name',
    last_name: 'last name',
    interface_language: 'interface language',
    data_language: 'data language',
    model_language: 'model language',
    languages_updated: 'your language settings have been updated',
  },
  no_metadata: 'no metadata',
}
