import script from "./App.vue?vue&type=script&setup=true&lang=ts"
export * from "./App.vue?vue&type=script&setup=true&lang=ts"

const __exports__ = script;

export default __exports__
import QLayout from 'quasar/src/components/layout/QLayout.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import qInstall from "../../../node_modules/.pnpm/vue-cli-plugin-quasar@4.0.4/node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QLayout,QSpace});
