export default {
  init () {
    // TODO: Init logger
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  logTrace (errInfoObj, vueInfoObj?) {
    console.log('LOGGING TRACE')
    console.log(errInfoObj)
    // TODO: Log something
  },
  logException (error) {
    console.log('LOGGING EXCEPTION')
    console.log(error)
    // TODO: Log something
  },
}
