import { Ref } from 'vue'
import { useEventListener } from '@vueuse/core'

export function useDoubleClickListener (
  target: Ref<HTMLElement | undefined> | HTMLElement,
  callback: (e: Event) => any,
) {
  let clickCounter = 0
  let clickTimer

  function clickHandler (e) {
    clickCounter++

    if (clickCounter === 1) {
      clickTimer = window.setTimeout(function () {
        clickCounter = 0
      }, 500)
    }

    if (clickCounter > 1) {
      clearTimeout(clickTimer)
      clickCounter = 0
      callback(e)
    }
  }

  useEventListener(target, 'click', clickHandler)
}
