import script from "./CColorPicker.vue?vue&type=script&setup=true&lang=ts"
export * from "./CColorPicker.vue?vue&type=script&setup=true&lang=ts"

const __exports__ = script;

export default __exports__
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QMenu from 'quasar/src/components/menu/QMenu.js';
import QColor from 'quasar/src/components/color/QColor.js';
import qInstall from "../../../../node_modules/.pnpm/vue-cli-plugin-quasar@4.0.4/node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QIcon,QMenu,QColor});
