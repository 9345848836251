<template>
  <component
    :is="to ? 'router-link' : 'div'"
    :to="to"
    class="block p-5 h-[220px] relative group cursor-pointer no-underline"
    :class="[`bg-${color}`, specialModifierClasses.card]"
  >
    <c-slide-effect :parent-color="color" />

    <c-context-menu
      class="absolute right-2 top-3"
      :class="specialModifierClasses.contextMenu"
    >
      <c-menu-item>
        menu item
      </c-menu-item>
    </c-context-menu>

    <div
      class="relative"
      :class="specialModifierClasses.text"
    >
      <div
        class="mt-16 text-grey-darker"
        :class="specialModifierClasses.text"
      >
        {{ title1 }}
      </div>
      <div class="text-h1 leading-none mb-4">
        {{ title2 }}
      </div>
      <div class="line-clamp-2">
        {{ description }}
      </div>
    </div>
  </component>
</template>

<script setup>
import { computed } from 'vue'
import { getRelatedColors } from '../../utils/colorUtils'

const props = defineProps({
  color: {
    type: String,
    default: 'secondary',
  },
  title1: {
    type: String,
    default: '',
  },
  title2: {
    type: String,
    default: '',
  },
  description: {
    type: String,
    default: '',
  },
  to: {
    type: [String, Object],
    default: undefined,
  },
})

const specialModifierClasses = computed(() => {
  const color = props.color
  const relatedColors = getRelatedColors(color)

  if (color === 'primary' || color === 'secondary' || color === 'accent') {
    return {
      card: '',
      contextMenu: 'text-' + relatedColors.onAltColor,
      text: 'text-' + relatedColors.onColor,
    }
  } else if (color === 'surface') {
    return {
      card: 'border border-surface-border',
      contextMenu: 'text-' + relatedColors.onColor,
      text: 'text-' + relatedColors.onColor,
    }
  } else if (color === 'white') {
    return {
      card: 'border border-grey',
      contextMenu: 'text-grey-darkest',
      text: 'text-grey-darkest',
    }
  } else if (color === 'black') {
    return {
      card: '',
      contextMenu: 'text-white',
      text: 'text-white',
    }
  }

  // Default for the rest
  return {
    card: '',
    contextMenu: 'text-white',
    text: 'text-grey-darkest',
  }
})
</script>
