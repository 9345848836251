import { createI18n } from 'vue-i18n'
// @ts-ignore
import en from '@/i18n/en'

// Preload English (required if we want a fallback language)
const i18n = createI18n({
  locale: 'en',
  fallbackLocale: 'en',
  messages: {
    en: en,
  },
})

const loadedLanguages = ['en']

function setI18nLanguage (lang) {
  i18n.global.locale = lang
  // @ts-ignore
  document.querySelector('html').setAttribute('lang', lang)
  return lang
}

// This will load our own custom i18n translation files
export function loadi18nLanguageAsync (lang) {
  // If the same language
  if (i18n.global.locale === lang) {
    return Promise.resolve(setI18nLanguage(lang))
  }

  // If the language was already loaded
  if (loadedLanguages.includes(lang)) {
    return Promise.resolve(setI18nLanguage(lang))
  }

  // If the language hasn't been loaded yet
  return import(
    /* webpackChunkName: "lang-[request]" */ '@/i18n/' + lang).then(
    messages => {
      i18n.global.setLocaleMessage(lang, messages.default)
      loadedLanguages.push(lang)
      return setI18nLanguage(lang)
    },
  )
}

export { i18n }
