import axios, { AxiosRequestConfig } from 'axios'
import axiosApiInstance from './axiosInstance'
import { FolderReadModel, FolderInputModel, FolderUpdateModel, AssetReadModel, AssetInputModel, AssetConfigurationReadModel, AssetVersionModel, CustomerEnvironment } from '@inriver/types'

export const getFolders = async (): Promise<FolderReadModel[]> => {
  const response = await axiosApiInstance.get('/api/folders')
  return response.data
}

export const getAssetsInFolder = async (folderId: string | null): Promise<AssetReadModel[]> => {
  const response = await axiosApiInstance.get(`/api/assets?folderId=${folderId || ''}`)
  return response.data
}

export const getSingleAssetInFolder = async (assetId: string): Promise<AssetReadModel> => {
  const response = await axiosApiInstance.get(`/api/assets/${assetId}`)
  return response.data
}

export const getAssetConfigurations = async (): Promise<AssetConfigurationReadModel[]> => {
  const response = await axiosApiInstance.get('/api/assetconfigurations')
  return response.data
}

export const addFolder = async (folder: FolderInputModel) => {
  return await axiosApiInstance.post('/api/folders', folder)
}

export const updateFolder = async (folderId: string, newAttrs: FolderUpdateModel) => {
  return await axiosApiInstance.put(`/api/folders/${folderId}`, newAttrs)
}

export const deleteFolder = async (folderId: string) => {
  return await axiosApiInstance.delete('/api/mediamanager/folders/' + folderId)
}

export const createAsset = async (asset: AssetInputModel, config?: AxiosRequestConfig) => {
  const data = new FormData()
  data.append('file', asset.file)
  data.append('folderId', asset.folderId as string)
  return await axiosApiInstance.post('/api/assets', data, config)
}

export const updateAsset = async (assetId: string, asset: AssetInputModel) => {
  return await axiosApiInstance.put(`/api/assets/${assetId}`, asset)
}

export const deleteAsset = async (assetId: string) => {
  return await axiosApiInstance.delete(`/api/assets/${assetId}`)
}

export const getAssetVersions = async (assetId: string): Promise<AssetVersionModel[]> => {
  const response = await axiosApiInstance.get(`/api/assets/${assetId}/versions`)
  return response.data
}

export const getCustomerEnvironment = async (): Promise<CustomerEnvironment> => {
  const response = await axiosApiInstance.get('/api/customerenvironment')
  return response.data
}

export const getPortalBaseUrl = async (): Promise<string> => {
  const response = await axiosApiInstance.get('/api/portalbaseurl')
  return response.data
}

export const getAssets = async (amount?: Number, randomize?: Boolean, exclude?: Number[]): Promise<AssetReadModel[]> => {
  const response = await axios.get('/api/mediamanager/assets', {
    params: {
      amount,
      randomize,
      exclude,
    },
  })
  return response.data.assets
}

export const updateLanguageCodes = async (interfaceLanguageCode: string, dataLanguageCode: string, modelLanguageCode: string) => {
  return await axios.put('/api/mediamanager/user/languages', {
    languages: {
      interfaceLanguageCode,
      dataLanguageCode,
      modelLanguageCode,
    },
  })
}
