import { defineStore } from 'pinia'
import { ref, computed, watch, readonly, Ref } from 'vue'
import axios from 'axios'
import { useI18n } from 'vue-i18n'
import { useFolderStore } from './folderStore'
import { invalidFileExtensions } from '@inriver/inri/src/utils/fileUtils'
import { notContains, maxFileSize } from '@inriver/inri/src/plugins/validationRules'
import logger from '@inriver/inri/src/plugins/errors/logger'
import { createAsset } from '@/apis/mediaManagerApi'
import { FileUploadModel, AssetInputModel } from '@inriver/types'
import FileUploadState from '@inriver/types/src/enums/FileUploadState'

interface ValidationResult {
  file: any,
  status: 'success' | 'failed'
  error?: string
}

interface QueuedUploadRequest {
  id: number,
  request: () => Promise<void>,
}

export const useFileUploadStore = defineStore(
  'file-upload',
  () => {
    const { t } = useI18n()
    const folderStore = useFolderStore()

    const MAX_FILE_SIZE = 1048576000// 1 GB in bytes
    const MAX_IMAGE_SIZE = 104857600// 100 MB in bytes
    const MAX_CONCURRENT_UPLOADS = 5

    // State
    const showUploadDialog = ref(false)
    const isProcessing = ref(false)
    const isUploading = ref(false)
    const uploads = ref<FileUploadModel[]>([])
    const queue = ref<QueuedUploadRequest[]>([])
    const queuedUploadRequestCount = ref(0)

    // These are meant to be used and exposed like getters
    const waitingUploads = ref<FileUploadModel[]>([])
    const ongoingUploads = ref<FileUploadModel[]>([])
    const failedUploads = ref<FileUploadModel[]>([])

    // Getters
    const uploadStatuses = computed(() => uploads.value.map(upload => upload.status))
    const hasFailedUploads = computed(() => failedUploads.value.length > 0)

    // Watcher
    watch(uploadStatuses, () => {
      waitingUploads.value = []
      ongoingUploads.value = []
      failedUploads.value = []

      uploads.value.forEach(upload => {
        switch (upload.status) {
          case FileUploadState.PROCESSING:
            waitingUploads.value.push(upload)
            break
          case FileUploadState.UPLOADING:
            ongoingUploads.value.push(upload)
            break
          case FileUploadState.ERROR:
            failedUploads.value.push(upload)
        }
      })
    })

    const validationFunctions = [
      notContains(
        invalidFileExtensions,
        false,
        t('inri.validation.fileTypeInvalid'),
      ),
      maxFileSize(
        parseInt(process.env.VUE_APP_MAX_FILE_SIZE) || MAX_FILE_SIZE,
        '',
        t('inri.validation.fileSizeTooBig'),
      ),
      maxFileSize(
        parseInt(process.env.VUE_APP_MAX_IMAGE_SIZE) || MAX_IMAGE_SIZE,
        'image',
        t('inri.validation.fileSizeTooBig'),
      ),
    ]

    function validateFiles (files: any[]): ValidationResult[] {
      return files.map(file => {
        for (const validate of validationFunctions) {
          const result = validate(file)

          if (typeof result === 'string') {
            return {
              file,
              status: 'failed' as const,
              error: result,
            }
          }
        }

        return {
          file,
          status: 'success' as const,
        }
      })
    }

    async function upload (files: any[]) {
      isProcessing.value = true
      const folderId = folderStore.currentFolderId

      // Validate and sort files
      const passedValidations: ValidationResult[] = []
      const failedValidations: ValidationResult[] = []

      for (const validationResult of validateFiles(files)) {
        if (validationResult.status === 'success') {
          passedValidations.push(validationResult)
        } else {
          failedValidations.push(validationResult)
        }
      }

      // Add files that failed validation to the uploads array
      uploads.value = uploads.value.concat(
        failedValidations.map(
          (result): FileUploadModel => ({
            folderId,
            filename: result.file.name,
            status: FileUploadState.ERROR,
            details: result.error,
          }),
        ),
      )

      const waitingUploads: Ref<FileUploadModel>[] = []

      for (const validationResult of passedValidations) {
        const waitingUpload: Ref<FileUploadModel> = ref({
          folderId,
          filename: validationResult.file.name,
          data: {
            file: validationResult.file,
            folderId,
          },
          status: FileUploadState.PROCESSING,
        })

        waitingUploads.push(waitingUpload)
        uploads.value.push(waitingUpload.value)
      }

      // Enqueue waiting uploads
      for (const upload of waitingUploads) {
        enqueue(upload.value)
      }

      isProcessing.value = false
      showUploadDialog.value = true
    }

    function enqueue (upload: FileUploadModel) {
      if (upload.status === FileUploadState.ERROR) {
        return
      }

      upload.queuedUploadRequestId = queuedUploadRequestCount.value++

      const uploadRequest = function () {
        const controller = new AbortController()
        upload.status = FileUploadState.UPLOADING
        upload.abort = controller.abort.bind(controller)

        return createAsset(
          upload.data as AssetInputModel,
          {
            signal: controller.signal,
          },
        ).then(() => {
          upload.status = FileUploadState.SUCCESS
        }).catch(err => {
          upload.status = FileUploadState.ERROR

          if (axios.isCancel(err)) {
            upload.details = t('upload.cancelled')
          } else {
            switch (err.response.status) {
              case 409:
                upload.details = t('upload.file_exists', { filename: upload.filename })
                break
              default:
                upload.details = t('upload.could_not_upload_file')
            }
          }
          logger.logTrace(err)
        })
      }

      queue.value.push({
        id: upload.queuedUploadRequestId as number,
        request: uploadRequest,
      })

      dequeue()
    }

    async function dequeue () {
      if (isUploading.value) {
        return
      }

      const queuedUploadRequests = queue.value.splice(0, MAX_CONCURRENT_UPLOADS)

      if (!queuedUploadRequests.length) {
        await folderStore.refreshAssetsInFolder()
        await folderStore.refreshFolders()
        return
      }

      isUploading.value = true

      Promise.allSettled(
        queuedUploadRequests.map(queuedUploadRequest => queuedUploadRequest.request()),
      ).then(() => {
        isUploading.value = false
        dequeue()
      })
    }

    function cancel (upload: FileUploadModel) {
      const waitingIndex = waitingUploads.value.indexOf(upload)
      if (waitingIndex) {
        waitingUploads.value.splice(waitingIndex, 1)
      }

      if (upload.queuedUploadRequestId) {
        const queueIndex = queue.value.findIndex(
          queuedUploadRequest => queuedUploadRequest.id === upload.queuedUploadRequestId,
        )
        if (queueIndex > -1) {
          queue.value.splice(queueIndex, 1)
        }
      }

      if (upload.abort) {
        upload.abort()
      }

      upload.status = FileUploadState.ERROR
      upload.details = t('upload.cancelled')
    }

    return {
      showUploadDialog,
      isProcessing,
      isUploading,
      uploads,
      hasFailedUploads,
      waitingUploads: readonly(waitingUploads),
      ongoingUploads: readonly(ongoingUploads),
      failedUploads: readonly(failedUploads),
      upload,
      cancel,
    }
  },
)
