import { defineStore } from 'pinia'
import { AssetReadModel, AssetConfigurationReadModel } from '@inriver/types'
import { getAssetConfigurations } from '@/apis/mediaManagerApi'

interface State {
  initialized: boolean,
  assetToPreview: AssetReadModel | null,
  transformations: AssetConfigurationReadModel[],
  tagList: string[],
}

export const useMediaManagerStore = defineStore({
  id: 'media-manager',
  state: (): State => ({
    initialized: false,
    assetToPreview: null,
    transformations: [],
    tagList: [],
  }),
  actions: {
    async init (data: State) {
      this.transformations = await getAssetConfigurations()
      this.tagList = data.tagList
      this.initialized = true
    },
    openPreview (asset: AssetReadModel) {
      this.assetToPreview = asset
    },
  },
})
