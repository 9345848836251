export enum FileUploadState {
  /**
   * Initial state. File is being handled by the front end
   */
  PROCESSING = 'processing',
  /**
   * File has been sent for upload
   */
  UPLOADING = 'uploading',
  /**
   * File successfully uploaded
   */
  SUCCESS = 'success',
  /**
   * File upload failed
   */
  ERROR = 'error',
}

export default FileUploadState
