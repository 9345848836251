import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

export const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'media-manager',
    component: () => import('../pages/folder/FolderPage.vue'),
    redirect: {
      name: 'media-manager.root',
      params: {
        folderId: null,
      },
    },
    meta: {
      title: 'media suite',
    },
    children: [
      {
        path: '',
        name: 'media-manager.root',
        component: () => import('../pages/folder/MainContent.vue'),
        props: true,
      },
      {
        path: 'folders/:folderId',
        name: 'media-manager.folder',
        component: () => import('../pages/folder/MainContent.vue'),
        props: true,
        meta: {
          title: 'media',
          parent: 'media-manager',
        },
      },
    ],
  },
  {
    path: '/details/:assetId',
    component: () => import('../pages/details/DetailsPage.vue'),
    props: true,
    name: 'media-manager.details-from-root',
    meta: {
      title: 'media manager',
      parent: 'media-manager.root',
    },
  },
  {
    path: '/folders/:folderId/details/:assetId',
    component: () => import('../pages/details/DetailsPage.vue'),
    props: true,
    name: 'media-manager.details',
    meta: {
      title: 'media manager',
      parent: 'media-manager.folder',
    },
  },
  {
    path: '/bin',
    component: () => import('../pages/recycle-bin/RecycleBinPage.vue'),
    props: true,
    name: 'media-manager.bin',
    meta: {
      title: 'bin',
      parent: 'media-manager',
    },
  },
  // Always leave this as last one
  {
    path: '/:catchAll(.*)*',
    name: '404',
    component: () => import(/* webpackChunkName: "404" */ '../pages/404.vue'),
    meta: {
      title: '404 - Page not found',
    },
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

export default router
