import script from "./CExpansionItem.vue?vue&type=script&setup=true&lang=ts"
export * from "./CExpansionItem.vue?vue&type=script&setup=true&lang=ts"

import "./CExpansionItem.vue?vue&type=style&index=0&id=c9ca3f20&lang=scss"

const __exports__ = script;

export default __exports__
import QExpansionItem from 'quasar/src/components/expansion-item/QExpansionItem.js';
import QItem from 'quasar/src/components/item/QItem.js';
import qInstall from "../../../../node_modules/.pnpm/vue-cli-plugin-quasar@4.0.4/node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QExpansionItem,QItem});
