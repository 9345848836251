import handleError from './handleError'
import { Exception } from './Exception'
import { ComponentPublicInstance } from 'vue'

export function VueErrorHandler (app) {
  // Exception handling within Vue components
  app.config.errorHandler = (err, vm: ComponentPublicInstance | null, info: string) => {
    let exception

    if (err instanceof Exception) {
      exception = err
    } else {
      exception = new Exception(err.message, err)
    }

    handleError(
      {
        err: exception,
        caughtBy: 'app.config.errorHandler',
      },
      {
        info: info,
      },
    )
  }
}
