import script from "./FileUploadOngoingIcon.vue?vue&type=script&setup=true&lang=ts"
export * from "./FileUploadOngoingIcon.vue?vue&type=script&setup=true&lang=ts"

const __exports__ = script;

export default __exports__
import QCircularProgress from 'quasar/src/components/circular-progress/QCircularProgress.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import qInstall from "../../../../../node_modules/.pnpm/vue-cli-plugin-quasar@4.0.4/node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QCircularProgress,QIcon});
