import { getCurrentInstance, computed, ref } from 'vue'

export default function useSelectable (allItems, selectedItems) {
  const { emit } = getCurrentInstance() as any

  // This will convert a "reactive" array to "ref". "ref"s will still be a ref.
  // This lets us use a reactive -or- ref array as a parameter.
  const _allItems = ref(allItems)

  const allSelectedStatus = computed(() => {
    if (!selectedItems.value || !selectedItems.value.length) {
      return false
    } else if (_allItems.value.length === selectedItems.value.length) {
      return true
    }

    return undefined
  })

  function toggleItem (evt, item = undefined) {
    // If someone passes just one parameter we assume it's the item that we want to toggle instead of the event.
    // The evt parameter is not actually used here, but is automatically passed along on a Q-Table row click.
    const _item = item || evt
    const existingItemIndex = selectedItems.value.findIndex(i => JSON.stringify(i) === JSON.stringify(_item))

    if (existingItemIndex >= 0) {
      // Remove item
      selectedItems.value.splice(existingItemIndex, 1)
    } else {
      // Add item
      selectedItems.value.push(_item)
    }
  }

  function toggleAllItems () {
    if (allSelectedStatus.value === false) {
      selectedItems.value = [..._allItems.value]
    } else {
      selectedItems.value = []
    }
  }

  function toggleAllItemsWithEmit (evt, emitString = 'update:modelValue') {
    if (allSelectedStatus.value === false) {
      emit(emitString, [..._allItems.value])
    } else {
      emit(emitString, [])
    }
  }

  function isSelected (item) {
    return selectedItems.value.includes(item)
  }

  return {
    allSelectedStatus,
    toggleItem,
    toggleAllItems,
    toggleAllItemsWithEmit,
    isSelected,
  }
}
