import useQuasar from 'quasar/src/composables/use-quasar.js';

// https://quasar.dev/vue-components/icon#svg-icon-format
// Usage:
// path|viewport
// With style (stroke="currentColor" can be converted into "stroke:currentColor;"):
// path@@style|viewport
// Multiple paths:
// path,path,path|viewport
// Multiple paths with style:
// path@@style,path@@style,path@@style|viewport

export const inriIcons = {
  inriver: [
    'M17.9834 17.6783C16.778 17.6783 15.6357 17.6783 14.4398 17.6783C14.4271 17.4061 14.405 17.1672 14.405 16.9283C14.4019 15.0924 14.4161 13.258 14.394 11.422C14.3892 10.9809 14.3339 10.5175 14.187 10.1067C13.8837 9.26115 13.2375 8.80255 12.3433 8.75159C11.4507 8.70064 10.7287 9.07643 10.3559 9.88217C10.1173 10.3997 9.98935 11.0096 9.97355 11.5844C9.92616 13.3774 9.95618 15.1736 9.95618 16.9682C9.95618 17.1879 9.95618 17.4076 9.95618 17.6704C8.73021 17.6704 7.56902 17.6704 6.35254 17.6704C6.35254 13.6927 6.35254 9.73089 6.35254 5.71338C7.53111 5.71338 8.70809 5.71338 9.92774 5.71338C9.9467 6.14331 9.96565 6.55573 9.98935 7.05255C10.1268 6.94268 10.1932 6.90287 10.2405 6.84714C11.4207 5.43312 12.9484 5.13694 14.6388 5.54459C16.2803 5.94108 17.2898 7.06051 17.7433 8.6672C17.9123 9.26752 18.0087 9.90924 18.0181 10.5334C18.0529 12.7564 18.0371 14.9793 18.0371 17.2022C18.0355 17.3408 18.0055 17.4793 17.9834 17.6783Z',
    '&&',
    'M52.2474 12.4314C52.4954 13.4393 53.0199 14.0731 53.8193 14.4696C55.1906 15.1479 56.6015 15.1049 58.0012 14.5922C58.6568 14.3518 59.2746 14.0062 59.9539 13.6877C59.9539 14.6702 59.9539 15.6352 59.9539 16.64C59.4152 17.1479 58.7327 17.4346 58.0059 17.6368C56.4751 18.0635 54.9316 18.14 53.3912 17.7291C50.9598 17.0795 49.4747 15.4887 49.0671 12.9903C48.6469 10.4139 49.252 8.11927 51.4369 6.53646C53.4181 5.10016 55.6204 4.96959 57.8179 6.02373C59.3772 6.77054 60.164 8.15589 60.4642 9.82946C60.6158 10.6734 60.638 11.5253 60.4373 12.4298C57.7279 12.4314 55.0453 12.4314 52.2474 12.4314ZM52.3864 9.9871C53.8888 9.9871 55.3581 9.9871 56.8353 9.9871C56.6899 8.90112 55.9806 8.22277 54.9853 8.1543C53.6803 8.06672 52.7277 8.72596 52.3864 9.9871Z',
    '&&',
    'M43.8909 17.699C42.8609 17.699 41.9319 17.699 40.9366 17.699C39.194 13.7372 37.4452 9.76109 35.6631 5.70854C37.0391 5.70854 38.3188 5.70854 39.6917 5.70854C40.5748 8.04453 41.4769 10.4251 42.3774 12.8073C42.4185 12.8025 42.4612 12.7977 42.5023 12.7913C43.4059 10.4219 44.3096 8.0509 45.207 5.6958C46.5309 5.6958 47.7932 5.6958 49.1676 5.6958C47.3903 9.74039 45.6446 13.7133 43.8909 17.699Z',
    '&&',
    'M70.9999 5.81375C70.9999 6.93318 70.9999 8.02872 70.9999 9.19114C70.7519 9.12107 70.5433 9.06693 70.3395 9.00324C68.112 8.30579 66.3109 9.43318 66.0739 11.7755C65.9412 13.0892 66.0044 14.422 65.9871 15.7469C65.9792 16.3743 65.9855 17.0016 65.9855 17.6704C64.75 17.6704 63.5888 17.6704 62.3818 17.6704C62.3818 13.6816 62.3818 9.71821 62.3818 5.70547C63.5588 5.70547 64.7184 5.70547 65.9728 5.70547C65.9728 6.28827 65.9728 6.86311 65.9728 7.43795C66.0218 7.45706 66.0708 7.47617 66.1213 7.49528C66.589 6.60356 67.2004 5.8647 68.1704 5.5542C69.1389 5.24369 70.0773 5.35515 70.9999 5.81375Z',
    '&&',
    'M23.9256 17.6847C22.6743 17.6847 21.529 17.6847 20.333 17.6847C20.333 13.68 20.333 9.70544 20.333 5.69589C21.529 5.69589 22.6901 5.69589 23.9098 5.69589C23.9098 6.29302 23.9098 6.85353 23.9098 7.41564C23.9556 7.4443 24.0014 7.47455 24.0472 7.50322C24.5164 6.6115 25.1247 5.87264 26.0852 5.56054C27.0521 5.24525 27.9905 5.35353 28.9195 5.80895C28.9195 6.922 28.9195 8.01436 28.9195 9.19908C28.6493 9.11627 28.4297 9.04939 28.2086 8.98251C26.1058 8.34239 24.4264 9.3599 24.0362 11.5367C23.9477 12.0335 23.9351 12.5494 23.9303 13.0558C23.9161 14.5781 23.9256 16.1019 23.9256 17.6847Z',
    '&&',
    'M0.396484 5.68799C1.60191 5.68799 2.74888 5.68799 3.95114 5.68799C3.95114 9.67843 3.95114 13.6577 3.95114 17.6752C2.771 17.6752 1.60981 17.6752 0.396484 17.6752C0.396484 13.6832 0.396484 9.71984 0.396484 5.68799Z',
    '&&',
    'M31.0547 5.68652C32.2491 5.68652 33.396 5.68652 34.6093 5.68652C34.6093 9.68015 34.6093 13.6547 34.6093 17.6722C33.426 17.6722 32.2649 17.6722 31.0547 17.6722C31.0547 13.6865 31.0547 9.72315 31.0547 5.68652Z',
    '&&',
    'M2.12566 -0.000438882C3.37374 -0.00840066 4.35641 0.947013 4.35799 2.16676C4.35799 3.32122 3.39112 4.27345 2.20939 4.283C0.948674 4.29574 0.0134034 3.4072 -0.000815246 2.18905C-0.0150339 0.961344 0.912338 0.0075229 2.12566 -0.000438882Z',
    '&&',
    'M32.8636 4.28504C31.6234 4.28982 30.6613 3.37103 30.6534 2.17198C30.6439 0.979307 31.6171 0.00319276 32.8178 8.04528e-06C34.0769 -0.00317667 35.0248 0.939498 35.0154 2.18472C35.0075 3.36307 34.069 4.28026 32.8636 4.28504Z',
    '|0 0 71 18'].join(''),
  'chevron-left': 'M20 38L4 20L20 2@@fill:none;stroke:currentColor;stroke-width:5;|0 0 22 40',
  'chevron-right': 'M2 2L18 20L2 38@@fill:none;stroke:currentColor;stroke-width:5;|0 0 22 40',

  // Make sure that these are exported as if in a 24x24 viewbox
  arrow: 'M14.3851 6.82322L14.2083 6.64645L14.0316 6.82322L13.0328 7.82197L12.8562 7.99856L13.0326 8.17533L15.8511 11H5H4.75V11.25V12.6667V12.9167H5H15.8502L13.0257 15.7411L12.8483 15.9185L13.0263 16.0953L14.0322 17.0941L14.209 17.2696L14.3851 17.0934L19.3434 12.1351L19.5202 11.9583L19.3434 11.7816L14.3851 6.82322Z@@stroke: currentColor;stroke-width: 0.5;',
  'grid-view': 'M5 5V19H19V5H5ZM11.2222 17.4444H6.55556V12.7778H11.2222V17.4444ZM11.2222 11.2222H6.55556V6.55556H11.2222V11.2222ZM17.4444 17.4444H12.7778V12.7778H17.4444V17.4444ZM17.4444 11.2222H12.7778V6.55556H17.4444V11.2222Z',
  'table-view': 'M19 5H5V19H19V5ZM6.4 9.2V6.4H17.6V9.2H6.4ZM6.4 13.4V10.6H17.6V13.4H6.4ZM6.4 17.6V14.8H17.6V17.6H6.4Z',
  search: 'M19.5875 17.7039L16.4973 14.6137C18.5903 11.4803 17.7474 7.2431 14.6139 5.15014C11.4804 3.05717 7.24314 3.90008 5.15015 7.03354C3.05716 10.167 3.90008 14.4042 7.03358 16.4971C9.32882 18.0306 12.3213 18.0299 14.6159 16.4958L17.7061 19.5854C18.2297 20.0956 19.064 20.0956 19.5875 19.5854C20.1151 19.0744 20.1437 18.2468 19.6321 17.7192C19.6221 17.7086 19.6122 17.6986 19.5875 17.7039ZM10.8297 6.00436C13.4935 6.00436 15.653 8.16385 15.653 10.8276C15.653 13.4914 13.4935 15.6509 10.8297 15.6509C8.1659 15.6509 6.00638 13.4914 6.00638 10.8276C6.00904 8.16518 8.16723 6.00702 10.8297 6.00436Z',
}

export const initInriIcons = (): void => {
  const $q = useQuasar()

  // Add inri icons
  $q.iconMapFn = (iconName) => {
    const inriIconsNamespace = 'inri-'

    if (iconName.startsWith(inriIconsNamespace)) {
      const name = iconName.substring(inriIconsNamespace.length)

      return {
        icon: inriIcons[name],
      }
    }
  }
}
