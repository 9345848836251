<template>
  <a
    href="#"
    class="flex items-center group"
  >
    <c-tile-btn
      icon="mdi-logout-variant"
      :icon-size="20"
      class="rotate-180"
    />
    <span class="text-h3 ml-4 group-hover:underline">
      {{ $t('sign_out') }}
    </span>
  </a>
</template>
