import Dialog from 'quasar/src/plugins/Dialog.js';
import ConfirmDialog from '../components/internal-components/ConfirmDialog.vue'
import { i18n } from './i18n'

const t = i18n.global.t

interface ConfirmDialogProps {
  title?: string,
  text: string,
  confirmButtonText?: string,
  cancelButtonText?: string,
  confirmButtonColor?: string,
}

export default {
  confirm: (props: ConfirmDialogProps) => {
    return Dialog.create({
      component: ConfirmDialog,
      componentProps: {
        ...props,
      },
    })
  },
  info: (props: ConfirmDialogProps) => {
    return Dialog.create({
      component: ConfirmDialog,
      componentProps: {
        ...props,
        confirmButtonText: props.confirmButtonText || t('inri.common.ok'),
        hideCancelButton: true,
      },
    })
  },
  delete: (props: ConfirmDialogProps) => {
    return Dialog.create({
      component: ConfirmDialog,
      componentProps: {
        ...props,
        title: props.title || t('inri.dialog.confirm_delete_title'),
        confirmButtonText: props.confirmButtonText || t('inri.common.delete'),
        confirmButtonColor: 'negative',
      },
    })
  },
}
