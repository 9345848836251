import { initInriIcons } from '../plugins/inriIcons'
import { useRouter, RouteLocationNormalized } from 'vue-router'
import { watch } from 'vue'
import { getRouteTitle } from '../utils/routeUtils'
import useMeta from 'quasar/src/composables/use-meta.js';
import { useInriStore } from '../stores/inriStore'

export default function useInriApp () {
  const router = useRouter()
  const inriStore = useInriStore()

  initInriIcons()

  router.beforeResolve((to) => {
    setPageTitle(to)
    setRouteTheme(to)
  })

  watch(inriStore.isAnyDirty, (value) => (
    setDirtyPageTitle(value)
  ))

  // Methods
  function setPageTitle (to: RouteLocationNormalized) {
    let pageTitle = ''

    if (to.meta.title) {
      pageTitle = getRouteTitle(to)
    } else if (to.matched[0]) {
      pageTitle = getRouteTitle(to.matched[0])
    } else {
      pageTitle = ''
    }

    document.title = pageTitle.length ? `inriver - ${pageTitle}` : 'inriver'
  }

  function setDirtyPageTitle (isDirty) {
    if (isDirty) {
      document.title = `* ${document.title}`
    } else {
      document.title = document.title.replace('* ', '')
    }
  }

  function setRouteTheme (to) {
    // This will create something like <body theme="route-demo-theme">
    // It can then be used as a CSS selector for overriding colors
    useMeta({
      bodyAttr: {
        theme: to.meta?.theme ? to.meta.theme : '',
      },
    })
  }
}
