import { computed, reactive } from 'vue'

interface State {
  dirtyComponents: any[],
  leftDrawerIsOpen: boolean,
  rightDrawerIsOpen: boolean,
  showSidebar: boolean,
  showPageSpinner: boolean,
  isGridView: boolean
}

const state = reactive<State>({
  dirtyComponents: [],
  leftDrawerIsOpen: false,
  rightDrawerIsOpen: false,
  showSidebar: true,
  showPageSpinner: false,
  isGridView: true,
})

const isAnyDirty = computed(() => {
  return !!state.dirtyComponents.length
})

function toggleLeftDrawer () {
  state.leftDrawerIsOpen = !state.leftDrawerIsOpen
}

function toggleRightDrawer () {
  state.rightDrawerIsOpen = !state.rightDrawerIsOpen
}

function toggleSidebar () {
  state.showSidebar = !state.showSidebar
}

function toggleDirtyComponent (item: any, add: boolean | undefined) {
  if (add && !state.dirtyComponents.find(i => i === item)) {
    state.dirtyComponents.push(item)
  } else {
    state.dirtyComponents = state.dirtyComponents.filter(i => i !== item)
  }
}

export const useInriStore = () => {
  return {
    state,
    isAnyDirty,
    toggleLeftDrawer,
    toggleRightDrawer,
    toggleSidebar,
    toggleDirtyComponent,
  }
}
