import { computed, reactive, ref } from 'vue'

export function useDirtyCheck (data?: object, immediate = true) {
  const isEnabled = ref(immediate)
  const reactiveData = reactive(data || {})
  const initialData = ref(JSON.stringify(reactiveData))

  const isDirty = computed(() => {
    if (!data || !isEnabled.value) {
      return false
    }

    return JSON.stringify(reactiveData) !== initialData.value
  })

  function freezeCleanData () {
    isEnabled.value = true
    initialData.value = JSON.stringify(reactiveData)
  }

  return {
    isDirty,
    freezeCleanData,
  }
}
