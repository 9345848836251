import { defineStore } from 'pinia'
import { updateLanguageCodes } from '@/apis/mediaManagerApi'
import { i18n } from '@inriver/inri/src/plugins/i18n'
import { dialog } from '@inriver/inri'

const $t = i18n.global.t

interface User {
  userName: string,
  firstName: string,
  lastName: string,
  interfaceLanguageCode: string,
  dataLanguageCode: string,
  modelLanguageCode: string,
}

interface Environment {
  name: string,
  customerName: string,
  count: number
}

interface State {
  initialized: boolean,
  user: User,
  environment: Environment
  portalBaseUrl: string,
}

export const useMainStore = defineStore({
  id: 'main',
  state: (): State => ({
    initialized: false,
    user: {
      userName: '',
      firstName: '',
      lastName: '',
      interfaceLanguageCode: '',
      dataLanguageCode: '',
      modelLanguageCode: '',
    },
    environment: {
      name: '',
      count: 0,
      customerName: '',
    },
    portalBaseUrl: '',
  }),
  getters: {
    languages () {
      return [
        {
          label: $t('inri.languages.en'),
          value: 'en',
        },
        {
          label: $t('inri.languages.de'),
          value: 'de',
        },
        {
          label: $t('inri.languages.fr'),
          value: 'fr',
        },
        {
          label: $t('inri.languages.es'),
          value: 'es',
        },
      ]
    },
    userDisplayName (state) {
      return `${state.user.firstName} ${state.user.lastName}`
    },
  },
  actions: {
    async init (data: State) {
      this.user = data.user
      this.environment = data.environment
      this.portalBaseUrl = data.portalBaseUrl
      this.initialized = true
    },
    async updateLanguageCodes (interfaceLanguageCode: string, dataLanguageCode: string, modelLanguageCode: string) {
      await updateLanguageCodes(interfaceLanguageCode, dataLanguageCode, modelLanguageCode)

      this.user.interfaceLanguageCode = interfaceLanguageCode
      this.user.dataLanguageCode = dataLanguageCode
      this.user.modelLanguageCode = modelLanguageCode
    },
    redirectWithNotify (portalUrl: string) {
      dialog.info({
        title: $t('inri.authentication.session_expired'),
        text: $t('inri.authentication.session_inactive'),
        confirmButtonText: $t('inri.authentication.log_in'),
      }).onOk(() => {
        window.location.href = portalUrl
      })
    },
    redirectWithOutNotify (portalUrl: string) {
      window.location.href = portalUrl
    },
    signOut () {
      window.location.href = `${this.portalBaseUrl}auth/signout`
    },
    changeEnvironment () {
      window.location.href = `${this.portalBaseUrl}environment/reroutetoenvironment`
    },
  },
})
