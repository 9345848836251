import { defineStore } from 'pinia'
import Quasar from 'quasar/src/vue-plugin.js';
import { loadi18nLanguageAsync } from '../plugins/i18n'

export const useInterfaceLanguageStore = defineStore({
  id: 'interfaceLanguage',
  state: () => ({}),
  actions: {
    async loadQuasarLanguageAsync (lang: string) {
      // IMPORTANT: Quasar uses "en-US" instead of "en"
      const qLangCode = lang === 'en' ? 'en-US' : lang

      const qLang = await import(
        /* webpackInclude: /(de|en-US|fr|es)\.js$/ */
        'quasar/lang/' + qLangCode
      )
      Quasar.lang.set(qLang.default)
    },
    async setLanguage (lang : string) {
      await loadi18nLanguageAsync(lang)
      await this.loadQuasarLanguageAsync(lang)
    },
    async init (lang: string) {
      await this.setLanguage(lang)
    },
  },
})
