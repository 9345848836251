import handleError from './handleError'

// Handle errors outside of components
window.addEventListener('error', (event) => {
  handleError(
    {
      err: event,
      caughtBy: 'window eventListener on error',
    },
  )
})

// Handle rejected promises outside components
window.addEventListener('unhandledrejection', (event) => {
  handleError(
    {
      err: event,
      caughtBy: 'window eventListener on unhandledrejection',
    },
  )
})
