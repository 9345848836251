import { FolderReadModel } from '@inriver/types'

/**
 * Returns the ancestors of a node in a tree structure
 *
 * @param {string} targetNodeKey The unique identifier of a node.
 * @param {Array<any>} nodes The array of nodes that designates the tree structure.
 * @param {string} nodeKey The property name of each node that holds a unique node id.
 * @param {string} childrenKey The property name of each node that holds the list of children of the node.
 *
 * @returns An array containing all the ancestor nodes in descending order. null if invalid node key is provided
 */
export function getParentNodes (targetNodeKey: string, nodes: any[], nodeKey = 'id', childrenKey = 'children') {
  if (typeof nodes !== 'undefined') {
    for (let i = 0; i < nodes.length; i++) {
      if (nodes[i][nodeKey] === targetNodeKey) {
        return []
      }

      const parents = getParentNodes(targetNodeKey, nodes[i][childrenKey])

      if (parents !== null) {
        parents.unshift(nodes[i])
        return parents
      }
    }
  }
  return null
}

/**
 * Returns the first folder in the provided folders array that has a matching id with the one provided.
 * If no match was found, null is returned
 *
 * @param {string} id - The id to be matched.
 * @param {FolderReadModel[]} folders - The array with folders to be searched through .
 * @returns The first folder in the array that has a matching id with the one provided. null if no match was found.
 */
export function findFolder (id, folders): FolderReadModel | null {
  const found = folders.find(folder => folder.id === id)

  if (!found && !folders.length) {
    return null
  }

  return found ?? findFolder(id, folders.filter(folder => folder.children).flatMap(folder => folder.children))
}
