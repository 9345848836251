import { getCurrentInstance, reactive, toRefs } from 'vue'
import notify from '../plugins/notify'
import { useDirtyCheck } from './useDirtyCheck'

export function useDialog (data?: object, immediate = true) {
  const { isDirty, freezeCleanData } = useDirtyCheck(data, immediate)
  const { emit } = getCurrentInstance() as any

  const state = reactive({
    isLoading: false,
    showDialog: true,
  })

  // Functions
  function closeAfterTimeout () {
    state.showDialog = false

    // Let the q-dialog close transition finish before we tell our parent to close.
    // This way we can use v-if on our parent to destroy the component but still have graceful transitions.
    setTimeout(() => {
      emit('update:show', false)
    }, 500)
  }

  function cancel () {
    closeAfterTimeout()
    emit('after-cancel')
  }

  function confirmSuccess (msg: string | null | undefined, returnValue: any | undefined = undefined) {
    closeAfterTimeout()
    state.isLoading = false
    emit('after-confirm', returnValue)

    if (msg) {
      notify.success(msg)
    }
  }

  return {
    ...toRefs(state),
    cancel,
    confirmSuccess,
    isDirty,
    freezeCleanData,
  }
}
