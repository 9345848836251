import { i18n } from '../i18n'

const $t = i18n.global.t
const $te = i18n.global.te

// ---------
// Interfaces
// ---------
export interface BaseError {
  title: string,
  msg: string,
  err: any,
  isAxiosError: boolean,
  status?: number
}

export interface BaseErrorArguments {
  defaultTitle?: string,
  defaultMsg?: string,
  title?: string,
  msg?: string,
  err: any
}

// ---------
// Implementations
// ---------
export class GenericError implements BaseError {
  title: string
  msg: string
  err: any
  isAxiosError = false

  constructor (
    public args: BaseErrorArguments,
    private defaultTitle = $t('inri.error.error'),
    private defaultMsg = $t('inri.error.error_occurred'),
  ) {
    this.defaultTitle = args?.defaultTitle || this.defaultTitle
    this.defaultMsg = args?.defaultMsg || this.defaultMsg
    this.title = args?.title ?? this.defaultTitle
    this.msg = args?.msg ?? this.defaultMsg
    this.err = args.err

    this.processError()
  }

  private processError () {
    let errorMessage

    if (this.err.type === 'unhandledrejection') {
      errorMessage = this.err.reason?.toString() || $t('inri.error.unknown_error')
    } else {
      errorMessage = this.err.msg || this.err.toString()
    }

    if (this.msg === this.defaultMsg) {
      this.msg = errorMessage
    }
  }
}

export class AxiosError implements BaseError {
  title: string
  msg: string
  err: any
  isAxiosError = true
  status = -1

  constructor (
    public args: BaseErrorArguments,
    private defaultTitle = $t('inri.error.network_error'),
    private defaultMsg = $t('inri.error.error_occurred'),
  ) {
    this.title = args?.title ?? this.defaultTitle
    this.msg = args?.msg ?? this.defaultMsg
    this.err = args.err

    this.processError()
  }

  private processError () {
    let errorMessage

    if (this.err.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx

      this.status = this.err.response.status
      errorMessage = $te(`inri.error.${this.err.response.data?.errorDescription}`)
        ? $t(`inri.error.${this.err.response.data?.errorDescription}`)
        : null
    } else if (this.err.request) {
      // The request was made but no response was received
      // `this.err.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js

      this.status = this.err.request.status
      errorMessage = this.err.request.statusText
    } else {
      // Something happened in setting up the request that triggered an Error

      errorMessage = this.err.message
    }

    if (errorMessage && this.msg === this.defaultMsg) {
      this.msg = errorMessage
    }
  }
}

// ---------
// Extensions
// ---------
export class UnauthorizedError extends AxiosError {
  constructor (public args: BaseErrorArguments) {
    super(
      args,
      $t('inri.error.unauthorized'),
      $t('inri.error.session_expired'),
    )
  }
}

export class NotFoundError extends AxiosError {
  constructor (public args: BaseErrorArguments) {
    super(
      args,
      $t('inri.error.not_found'),
      $t('inri.error.requested_asset_not_found'),
    )
  }
}

export class ServiceUnavailableError extends AxiosError {
  constructor (public args: BaseErrorArguments) {
    super(
      args,
      $t('inri.error.service_unavailable'),
      $t('inri.error.service_unavailable_try_again'),
    )
  }
}

// ---------
// Specific error subtypes
// ---------
export class FolderNotFoundError extends AxiosError {
  constructor (public args: BaseErrorArguments) {
    super(
      args,
      $t('inri.error.not_found'),
      $t('inri.error.could_not_retrieve_assets'),
    )
  }
}
