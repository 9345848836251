export const invalidFileChars = ['#', '%', '&', '{', '}', '\\', '<', '>', '*', '?', '/', '$', '!', "'", '"', ':', '@', '+', '`', '|', '=']

export const maxFileLength = 30

export const forbiddenFilePrefixAndSuffix = ['.', '-', '_']

export const invalidFileExtensions = ['.exe']

export function getFileExtension (fileName = '') {
  if (fileName.includes('.')) {
    return fileName.substring(fileName.lastIndexOf('.') + 1, fileName.length)
  }

  return ''
}

/**
 * Converts bytes to an appropriate size unit, outputted in a readable format
 * @param {number} bytes - Value to be converted and formatted
 * @param {number} [decimals] - Specifies how many decimals the result should contain, defaults to 0 (no decimals)
 * @returns {string} The converted value in a readable format
 */
export function bytesToReadableSize (bytes: number, decimals = 0) {
  const sizes = ['Bytes', 'KB', 'MB', 'GB']

  if (bytes === 0) {
    return '0 Bytes'
  }

  const dm = decimals < 0 ? 0 : decimals
  const i = Math.floor(Math.log(bytes) / Math.log(1024))

  return (bytes / Math.pow(1024, i)).toFixed(dm) + ' ' + sizes[i]
}

/**
 * Converts a file to a Base64 encoded string
 * @param { File | any } file - File object
 * @returns A promise that returns a Base64 encoded string when resolved
 */
export function convertFileToBase64 (file) {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = () => {
      const result = reader.result as string
      resolve(result.split(',').pop() as string)
    }
    reader.onerror = reject
    reader.readAsDataURL(file)
  })
}

export const textFileExtensionWhitelist = ['txt', 'md', 'xml']

export function isTextFile (filename: string) {
  return textFileExtensionWhitelist.includes(getFileExtension(filename).toLowerCase())
}
