// Always include the translations required by the library
import { en } from '@inriver/inri/i18n'

// Include app translations here
import mediaManager from './mediaManager'

export default {
  ...en,
  ...mediaManager,
}
