import { i18n } from '../plugins/i18n'

/**
 * Try to get an error message returned from the server. It could be a i18n key or just a normal error message.
 * If a namespace is given then try to find the translation here. Fallback to the shared generic errors.
 * @param [error] - Exception
 * @param {string?} [namespace] - optional namespace where you want to find your i18n translation ('folders' will try to get from 'folders.foo_error')
 */
export const getErrorMessage = (error: any, namespace: string | undefined = undefined) : string => {
  const t = i18n.global.t
  const te = i18n.global.te

  const errorDescription = error.response?.data?.errorDescription ||
    error.response?.data?.message ||
    error.response?.data ||
    error.request?.statusText ||
    error?.message ||
    error.toString()

  if (!errorDescription) {
    return t('inri.error.undefined_error')
  }

  let translatedError

  // First try to get the translation from the given namespace
  if (namespace) {
    translatedError = te(`${namespace}.${errorDescription}`) ? t(`${namespace}.${errorDescription}`) : undefined
  }

  // If not found in namespace or namespace is not defined try to get it from the global errors
  if (!translatedError) {
    translatedError = te(`inri.error.${errorDescription}`) ? t(`inri.error.${errorDescription}`) : undefined
  }

  return translatedError || errorDescription || t('inri.error.undefined_error')
}
