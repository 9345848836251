import Notify from 'quasar/src/plugins/Notify.js';
import CSpinner from '../components/CSpinner.vue'

// Register custom predefined notification types
Notify.registerType('c-notify-error', {
  classes: 'c-notify',
  color: 'red-light',
  icon: 'mdi-alert-circle-outline',
  iconColor: 'red',
  textColor: 'grey-darkest',
  actions: [
    {
      class: 'c-notify__btn',
      color: 'grey-darkest',
      icon: 'mdi-close',
      'aria-label': 'Dismiss',
    },
  ],
})

Notify.registerType('c-notify-success', {
  classes: 'c-notify',
  color: 'green-light',
  icon: 'mdi-check-circle-outline',
  iconColor: 'green',
  textColor: 'grey-darkest',
  actions: [
    {
      class: 'c-notify__btn',
      color: 'grey-darkest',
      icon: 'mdi-close',
      'aria-label': 'Dismiss',
    },
  ],
})

Notify.registerType('c-notify-warning', {
  classes: 'c-notify',
  color: 'yellow-light',
  icon: 'mdi-alert-outline',
  iconColor: 'yellow',
  textColor: 'grey-darkest',
  actions: [
    {
      class: 'c-notify__btn',
      color: 'grey-darkest',
      icon: 'mdi-close',
      'aria-label': 'Dismiss',
    },
  ],
})

Notify.registerType('c-notify-info', {
  classes: 'c-notify',
  color: 'blue-light',
  icon: 'mdi-information-outline',
  iconColor: 'blue',
  textColor: 'grey-darkest',
  actions: [
    {
      class: 'c-notify__btn',
      color: 'grey-darkest',
      icon: 'mdi-close',
      'aria-label': 'Dismiss',
    },
  ],
})

Notify.registerType('c-notify-ongoing', {
  classes: 'c-notify',
  timeout: 0,
  spinner: CSpinner,
  color: 'grey-darkest',
  actions: [
    {
      class: 'c-notify__btn',
      color: 'white',
      icon: 'mdi-close',
      'aria-label': 'Dismiss',
    },
  ],
})

// Define helper functions for displaying notifications
const error = (message, props = {}) => {
  Notify.create({
    message,
    type: 'c-notify-error',
    attrs: {
      'data-id': 'toast',
    },
    ...props,
  })
}

const success = (message, props = {}) => {
  Notify.create({
    message,
    type: 'c-notify-success',
    attrs: {
      'data-id': 'toast',
    },
    ...props,
  })
}

const warning = (message, props = {}) => {
  Notify.create({
    message,
    type: 'c-notify-warning',
    attrs: {
      'data-id': 'toast',
    },
    ...props,
  })
}

const info = (message, props = {}) => {
  Notify.create({
    message,
    type: 'c-notify-info',
    attrs: {
      'data-id': 'toast',
    },
    ...props,
  })
}

/**
 * Creates an ongoing notification
 * @param message - The message to be displayed
 * @param props - Notification props to be passed to the notification instance
 * @returns a function to update the ongoing notification. Calling this function without any parameters closes the notification
 */
const ongoing = (message: string, props = {}) => {
  return Notify.create({
    message,
    type: 'c-notify-ongoing',
    attrs: {
      'data-id': 'toast',
    },
    group: false,
    ...props,
  })
}

export default {
  error,
  success,
  warning,
  info,
  ongoing,
}
