import '../styles/main.scss'
import 'windi.css'
import { App } from 'vue'
import Quasar from 'quasar/src/vue-plugin.js';
import quasarUserOptions from '../plugins/quasar-user-options'
import { registerComponents } from '../plugins/library'
import { registerAppConfigurations } from '../plugins/globalAppConfig'
import { i18n } from '../plugins/i18n'
import logger from '../plugins/errors/logger'
import { VueErrorHandler } from '../plugins/errors/vueErrorHandler'
import '../plugins/errors/globalErrorHandler'

export const initInriFramework = (Vue: App): void => {
  // Register C-components
  registerComponents(Vue)

  // Register global application configurations
  registerAppConfigurations(Vue)

  Vue.use(Quasar, quasarUserOptions)
  Vue.use(i18n)
  Vue.use(VueErrorHandler)

  logger.init()
}
