import { useMainStore } from '@/stores'
import axios from 'axios'
import { getPortalBaseUrl } from './mediaManagerApi'

const axiosApiInstance = axios.create()

axiosApiInstance.interceptors.response.use(async function (response) {
  // Any status code that lie within the range of 2xx cause this function to trigger
  return response
}, async function (error) {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  if (error.response.status === 401) {
    const mainStore = useMainStore()
    const redirectToPortal = await getPortalBaseUrl()
    mainStore.redirectWithNotify(redirectToPortal)
  }
  return Promise.reject(error)
})

export default axiosApiInstance
