import iconSet from 'quasar/icon-set/mdi-v6'
import '@quasar/extras/mdi-v6/mdi-v6.css'

import Notify from 'quasar/src/plugins/Notify.js';import Dialog from 'quasar/src/plugins/Dialog.js';import Meta from 'quasar/src/plugins/Meta.js';

// Override specific iconSet icons
iconSet.field.error = 'mdi-alert-circle-outline'

export default {
  plugins: {
    Notify,
    Dialog,
    Meta,
  },
  config: {
    notify: { /* look at QuasarConfOptions from the API card */ },
  },
  iconSet: iconSet,
}
