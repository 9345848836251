import { App } from 'vue'
import { rules } from './validationRules'
import notify from './notify'
import dialog from './dialog'
import { colors } from '../utils/colorUtils'
import { quasarComponentConfigurations } from './inriverQuasarComponentConfigurations'

// register components
export const registerAppConfigurations = (Vue: App): void => {
  // Expose $validate in templates
  Vue.config.globalProperties.$validate = rules

  // Expose $notify in templates
  Vue.config.globalProperties.$notify = notify

  // Expose $dialog in templates
  Vue.config.globalProperties.$dialog = dialog

  // Expose colors so it's easy to loop them on demo pages
  Vue.config.globalProperties.$colors = colors

  // Configurations that can be used on quasar components. Example:
  // <q-input v-bind="$inri.input" />
  Vue.config.globalProperties.$inri = quasarComponentConfigurations
}
