import { i18n } from './i18n'

const $t = i18n.global.t

/**
 * The value is required.
 * @param {string} [msg] - Override the default error message
 */
export function required (msg?: string) {
  return (value): true | string => {
    return !!value || msg || $t('inri.validation.required')
  }
}

/**
 * The value must have a minimum length.
 * @param {string | number} length - Min length
 * @param {string} [msg] - Override the default error message
 */
export function minLength (length: string | number, msg?: string) {
  const parsedLength = typeof length === 'number' ? length : parseInt(length, 10)
  return (value): true | string => {
    if (!value) {
      return true
    }

    return value.length >= parsedLength || msg || $t('inri.validation.minLength', { parsedLength: parsedLength })
  }
}

/**
 * The value can not exceed maximum length.
 * @param {string | number} length - Max length
 * @param {string} [msg] - Override the default error message
 */
export function maxLength (length: string | number, msg?: string) {
  const parsedLength = typeof length === 'number' ? length : parseInt(length, 10)
  return (value): true | string => {
    if (!value) {
      return true
    }

    return value.length <= parsedLength || msg || $t('inri.validation.maxLength', { parsedLength: parsedLength })
  }
}

/**
 * The value must be a valid email.
 * @param {string} [msg] - Override the default error message
 */
export function email (msg?: string) {
  // eslint-disable-next-line no-useless-escape
  const regExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return (value): true | string => {
    if (!value) {
      return true
    }

    return regExp.test(String(value).toLowerCase()) || msg || $t('inri.validation.email')
  }
}

/**
 * The value must be a valid url.
 * @param {string} [msg] - Override the default error message
 */
export function url (msg?: string) {
  // eslint-disable-next-line no-useless-escape
  const regExp = /^(https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*))$/
  return (value): true | string => {
    if (!value) {
      return true
    }

    return regExp.test(String(value).toLowerCase()) || msg || $t('inri.validation.url')
  }
}

/**
 * The value must be a valid url using https.
 * @param {string} [msg] - Override the default error message
 */
export function https (msg?: string) {
  // eslint-disable-next-line no-useless-escape
  const regExp = /^(https:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*))$/
  return (value): true | string => {
    if (!value) {
      return true
    }

    return regExp.test(String(value).toLowerCase()) || msg || $t('inri.validation.https')
  }
}

/**
 * Validate file extensions. Supports single and multiple files.
 * @param {string} fileExtensions - A string containing a single file extension, "jpg", or a comma separated string, "jpg,png".
 * @param {string} [msg] - Override the default error message
 */
export function extension (fileExtensions: string, msg?: string) {
  // Extension string cleanup and convert to array
  const extensions = fileExtensions
    .toLowerCase()
    .replaceAll(' ', '')
    .replaceAll('.', '')
    .split(',')

  return (value): true | string => {
    if (!value) {
      return true
    }

    let files: any[] = []
    let isValid = true

    // If single file then push it into an array for consistency
    if (!value.length) {
      files.push(value)
    } else {
      files = value
    }

    for (const file of files) {
      const fileExt = file.name.substring(file.name.lastIndexOf('.') + 1).toLowerCase()
      if (!extensions.includes(fileExt)) {
        isValid = false
        break
      }
    }

    return isValid || msg || $t('inri.validation.extension', { extensions: extensions.join(', .') })
  }
}

/**
 * The value must contain all the strings in the array.
 * @param {string[]} arr - Array of strings.
 * @param {boolean} caseSensitive
 * @param {string} [msg] - Override the default error message
 */
export function contains (arr: string[], caseSensitive = false, msg?: string) {
  return (value): true | string => {
    if (!value) {
      return true
    }

    let foundItems

    if (!caseSensitive) {
      foundItems = arr.filter(item => !value.toUpperCase().includes(('' + item).toUpperCase()))
    } else {
      foundItems = arr.filter(item => !value.includes('' + item))
    }

    if (!foundItems.length) {
      return true
    }

    return msg || $t('inri.validation.contains', { match: foundItems.join(', ') })
  }
}

/**
 * The value must not contain any of the strings in the array.
 * @param {string[]} arr - Array of strings.
 * @param {boolean} caseSensitive
 * @param {string} [msg] - Override the default error message
 */
export function notContains (arr: string[], caseSensitive = false, msg?: string) {
  return (value): true | string => {
    if (!value) {
      return true
    }

    const comparisonValue = value instanceof File ? value.name : value

    let foundItems

    if (!caseSensitive) {
      foundItems = arr.filter(item => comparisonValue.toUpperCase().includes(('' + item).toUpperCase()))
    } else {
      foundItems = arr.filter(item => comparisonValue.includes('' + item))
    }

    if (!foundItems.length) {
      return true
    }

    return msg || $t('inri.validation.notContains', { match: foundItems.join(', ') })
  }
}

/**
 * The value must not begin with any of the strings in the array.
 * @param {string[]} arr - Array of strings.
 * @param {boolean} caseSensitive
 * @param {string} [msg] - Override the default error message
 */
export function notBeginsWith (arr: string[], caseSensitive = false, msg?: string) {
  return (value): true | string => {
    if (!value) {
      return true
    }

    let foundItems

    if (!caseSensitive) {
      foundItems = arr.filter(item => value.toUpperCase().startsWith(('' + item).toUpperCase()))
    } else {
      foundItems = arr.filter(item => value.startsWith('' + item))
    }

    if (!foundItems.length) {
      return true
    }

    return msg || $t('inri.validation.notBeginsWith', { match: foundItems.join(', ') })
  }
}

/**
 * The value must not end with any of the strings in the array.
 * @param {string[]} arr - Array of strings.
 * @param {boolean} caseSensitive
 * @param {string} [msg] - Override the default error message
 */
export function notEndsWith (arr: string[], caseSensitive = false, msg?: string) {
  return (value): true | string => {
    if (!value) {
      return true
    }

    let foundItems

    if (!caseSensitive) {
      foundItems = arr.filter(item => value.toUpperCase().endsWith(('' + item).toUpperCase()))
    } else {
      foundItems = arr.filter(item => value.endsWith('' + item))
    }

    if (!foundItems.length) {
      return true
    }

    return msg || $t('inri.validation.notEndsWith', { match: foundItems.join(', ') })
  }
}

/**
 * The file(s) cannot exceed max size
 * @param {string | number} size - Max size in bytes
 * @param {string} [type] - Only validate against files with this MIME type
 * @param {string} [msg] - Override the default error message
 */
export function maxFileSize (size: string | number, type?: string, msg?: string) {
  const parsedFileSize = typeof size === 'number' ? size : parseInt(size, 10)

  return (value): true | string => {
    if (!value) {
      return true
    }

    let files: any[] = []

    if (!value.length) {
      files.push(value)
    } else {
      files = value
    }

    if (type) {
      files = files.filter(file => file.type.includes(type))
    }

    return files.every(file => file.size <= size) || msg || $t('inri.validation.maxFileSize', { parsedFileSize })
  }
}

export const rules = {
  required,
  maxLength,
  minLength,
  email,
  url,
  https,
  extension,
  contains,
  notContains,
  notBeginsWith,
  notEndsWith,
  maxFileSize,
}
