import {
  BaseError,
  BaseErrorArguments,
  GenericError,
  AxiosError,
  UnauthorizedError,
  NotFoundError,
  FolderNotFoundError,
  ServiceUnavailableError,
} from './Errors'
import axios from 'axios'

export class ErrorFactory {
  static createHelpfulError (args: BaseErrorArguments): BaseError {
    if (axios.isAxiosError(args.err)) {
      return args.err.response
        ? this.createHelpfulErrorResponse(args.err.response.status, args)
        : new AxiosError(args)
    }

    return new GenericError(args)
  }

  static createHelpfulErrorResponse (errorCode: number, args: BaseErrorArguments): AxiosError {
    switch (errorCode) {
      case 401:
        return new UnauthorizedError(args)
      case 404:
        return new NotFoundError(args)
      case 408:
      case 503:
      case 504:
        return new ServiceUnavailableError(args)
      default:
        return new AxiosError(args)
    }
  }
}

export class FolderErrorFactory extends ErrorFactory {
  static createHelpfulError (args: BaseErrorArguments) {
    return super.createHelpfulError(args)
  }

  static createHelpfulErrorResponse (errorCode: number, args: BaseErrorArguments): AxiosError {
    switch (errorCode) {
      case 401:
        return new UnauthorizedError(args)
      case 404:
        return new FolderNotFoundError(args)
      case 408:
      case 503:
      case 504:
        return new ServiceUnavailableError(args)
      default:
        return new AxiosError(args)
    }
  }
}
