import { defineStore } from 'pinia'
import { AssetReadModel, AssetVersionModel } from '@inriver/types'
import { getSingleAssetInFolder, deleteAsset, getAssetVersions } from '@/apis/mediaManagerApi'

interface State {
  asset: AssetReadModel | null,
  assetVersions: AssetVersionModel[]
  assetIsLoading: boolean,
  showInformation: boolean,
}

export const useDetailsStore = defineStore({
  id: 'details',
  state: (): State => ({
    asset: null,
    assetVersions: [],
    assetIsLoading: true,
    showInformation: false,
  }),
  actions: {
    async populateAsset (assetId: string) {
      this.asset = await getSingleAssetInFolder(assetId)
    },
    async populateAssetVersions (assetId: string) {
      this.assetVersions = await getAssetVersions(assetId)
    },
    async deleteAsset () {
      await deleteAsset(this.asset?.id as string)
    },
    toggleInformation () {
      this.showInformation = !this.showInformation
    },
  },
  getters: {
    hasVersions (state) {
      return state.assetVersions.length > 1
    },
  },
})
