import { AssetReadModel } from '@inriver/types'

export const defaultPreviewTransformations = 'c_limit,w_1929,h_1080'

export const defaultTransformationOption = {
  name: '(default)',
  transformations: defaultPreviewTransformations,
}

export function generateTransformationUrl (url: string, transformations: string, extension?: string) {
  const urlWithReplacedFileExtension = url.substr(0, url.lastIndexOf('.')) + extension
  return urlWithReplacedFileExtension.replace('/upload/', `/upload/${transformations}/`)
}

export function getThumbnail (asset: AssetReadModel) {
  return generateTransformationUrl(
    asset.url,
    'c_limit,w_500,h_200',
    '.jpg',
  )
}

export function getImagePreview (asset: AssetReadModel) {
  return generateTransformationUrl(
    asset.url,
    defaultPreviewTransformations,
    asset.format === 'gif' ? '.gif' : '.jpg',
  )
}

export function getVideoPreview (asset: AssetReadModel, extension: string) {
  return generateTransformationUrl(
    asset.url,
    defaultPreviewTransformations,
    extension,
  )
}

export function getPdfPagePreview (asset: AssetReadModel, page = 1) {
  return generateTransformationUrl(
    asset.url,
    `${defaultPreviewTransformations},pg_${page}`,
    '.jpg',
  )
}

export function getTransformedAsset (asset: AssetReadModel, transformations: string, extension = '.jpg') {
  return generateTransformationUrl(
    asset.url,
    transformations,
    extension,
  )
}

export function getDownloadableTransformedAsset (asset: AssetReadModel, transformations: string) {
  return asset.url.replace('/upload/', `/upload/${transformations}/fl_attachment/`)
}
