interface RelatedColors {
  onColor: string,
  onAltColor?: string,
  effectColor: string,
}

export const baseColors = ['primary', 'secondary', 'accent', 'surface']
export const blackAndWhiteColors = ['black', 'white']
export const spotColors = ['blue', 'purple', 'red', 'orange', 'yellow', 'green']
export const lightColors = ['blue-light', 'purple-light', 'red-light', 'orange-light', 'yellow-light', 'green-light']
export const greyColors = ['grey-darkest', 'grey-darker', 'grey-dark', 'grey', 'grey-light', 'grey-lighter']

export const colors = {
  baseColors,
  blackAndWhiteColors,
  spotColors,
  lightColors,
  greyColors,
}

export const getRelatedColors = (color): RelatedColors | null => {
  if (color === 'primary') {
    return {
      onColor: 'on-primary',
      onAltColor: 'on-primary-alt',
      effectColor: 'primary-effect',
    } as RelatedColors
  } else if (color === 'secondary') {
    return {
      onColor: 'on-secondary',
      onAltColor: 'on-secondary-alt',
      effectColor: 'secondary-effect',
    } as RelatedColors
  } else if (color === 'accent') {
    return {
      onColor: 'on-accent',
      onAltColor: 'on-accent-alt',
      effectColor: 'accent-effect',
    } as RelatedColors
  } else if (color === 'surface') {
    return {
      onColor: 'on-surface',
      effectColor: 'surface-effect',
    } as RelatedColors
  } else if (spotColors.includes(color)) {
    return {
      onColor: 'grey-darkest',
      effectColor: `${color}-light`,
    } as RelatedColors
  } else if (color === 'black') {
    return {
      onColor: 'white',
      effectColor: 'grey-darker',
    } as RelatedColors
  } else if (color === 'white') {
    return {
      onColor: 'grey-darkest',
      effectColor: 'grey-lighter',
    } as RelatedColors
  } else if (color === 'grey-lighter') {
    return {
      onColor: 'grey-darkest',
      effectColor: 'grey-light',
    } as RelatedColors
  } else if (color.endsWith('-light')) {
    return {
      onColor: 'grey-darkest',
      effectColor: color.replace('-light', ''),
    } as RelatedColors
  }

  return null
}
